export const validationMessage = {
  LOGIN: {
    EMAIL_VALIDATION: "Please enter a valid email address.",
    EMAIL_EXISTS: "Email address is not registered. Please sign up.",
    EMAIL_VERIFICATION_MESSAGE: "Please enter the verification code sent to the email address.",
    INVALID_CODE: "Invalid Verification code.",
    CAPTCHA_VALIDATION: "Please verify that you are not a robot.",
  },

  SIGNUP: {
    FIRST_NAME: "Please enter your first name.",
    LAST_NAME: "Please enter your last name.",
    AGENCY_NAME: "Please enter your agency name.",
    AGENCY_STATE: "Please enter your agency state.",
    USG_PARTNER_ID: "Please enter valid USG Partner ID.",
    EMAIL: "Please enter a valid email address.",
    CAPTCHA_VALIDATION: "Please verify that you are not a robot.",
    ACCEPT_TERMS: "Please agree to our Terms of Use and Privacy Policy."
  },

  POLICY_CHECK: {
    DESCRIPTION: "Please enter description.",
    LINE_OF_BUSINESS: "Please select line of business.",
    WORK_TYPE: "Please select work type.",
    DOCUMENT_TYPE: "Please select document type.",
    BASE_DOCUMENT: "Please select base document.",
    UPLOAD_DOCUMENT:"Please upload at least two or maximum three documents."
  },

  CARD_PAYMENT : {
    NAME_ON_CARD : "Please enter the name mentioned on the card.",
    STREET_ADDRESS : "Please Enter Street Address.",
    CITY : "Please Enter City.",
    STATE : "Please Enter State.",
    POST_CODE : "Please Enter Post Code.",
  }

}