import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private isModalOpen = false;

  constructor() { }

  private emailSubject = new BehaviorSubject<string>('');
  email$ = this.emailSubject.asObservable()

  private captchaSubject = new BehaviorSubject<string>('');
  captcha$ = this.captchaSubject.asObservable()

  private policyQuoteSubject = new BehaviorSubject<string>('');
  policyQuote$ = this.policyQuoteSubject.asObservable()

  private billingInfoActiveTabSubject = new BehaviorSubject<string>('');
  billingInfoActiveTab$ = this.billingInfoActiveTabSubject.asObservable()

  setEmail(email: string) {
    this.emailSubject.next(email);
  }

  setRecaptcha(captcha : string){
  this.captchaSubject.next(captcha)
  }
  
  setPolicyQuoteValue(val : string){
  this.policyQuoteSubject.next(val)
  }

  setBillingInfoActiveState(val : string){
  this.billingInfoActiveTabSubject.next(val)
  }

  public getModalState(): boolean {
    return this.isModalOpen;
  }

  public setModalState(state: boolean): void {
    this.isModalOpen = state;
  }

   // Sanitize the input string
   sanitizeInput(value: string): string {
    // Remove any characters that are not letters or spaces
    let sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '');
  
    // Replace multiple spaces with a single space
    sanitizedValue = sanitizedValue.replace(/\s+/g, ' ');
  
    // Prevent space as the first character
    if (sanitizedValue.startsWith(' ')) {
      sanitizedValue = sanitizedValue.substring(1);
    }
    return sanitizedValue;
  }

}
