export const environment = {
    production: false,
    baseUrl: "https://api.selfservice-dev.bluepond.ai/",
    recaptchaSiteKey : "6LdHYhwqAAAAAIqM2V4ienDWtzrl6HwD3y3riTK9",
    countDownTime : 60,  // Initial countdown time in seconds
    stripeKey : "pk_test_51PiwYQBcK7PzriOxV9to2hQ4wJwZdJawVgFBds9gcpHi59yLO4VXEXjNvEuaVkXIoVp2BB86lUYw6KHn0MgujCRF002jyn9IBE",
    //  STORAGE_CONNECTION_STRING :"https://usgdevdocs.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=c&sp=rwdlacupiytfx&se=2024-09-30T18:22:04Z&st=2024-09-04T10:22:04Z&spr=https&sig=EJHuwKOCKNVe56MyOv5ea7w%2B3mOmzRS%2BGT08jLqKBuM%3D",
    // containerName : "usg-documents",
    client:"bp"
};
