import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export const appInterceptor: HttpInterceptorFn = (req, next) => {

  const modifiedReq = req.clone({
    url : `${environment.baseUrl}${req.url}`
  })
  return next(modifiedReq);
};

