import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { appInterceptor } from './shared/interceptor/app.interceptor';
import { provideNgxStripe } from 'ngx-stripe';

 
import { authInterceptor } from './shared/interceptor/auth.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideHttpClient(withInterceptors([appInterceptor])), 
    provideClientHydration(), 
    provideAnimationsAsync(),
    provideToastr( {
      positionClass: 'toast-top-center',
    }),
    provideNgxStripe(),
    provideHttpClient(withInterceptors([authInterceptor]))]
};
