import { Component, ElementRef, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef , MatDialogClose} from '@angular/material/dialog';
import { DataService } from '../../modules/service/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { validationMessage } from '../message/validationMessage';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../services/common.service';
import { SafeUrlPipe } from '../Pipe/safe-url.pipe';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [MatDialogClose , CommonModule , FormsModule , ReactiveFormsModule , NgbModule , SafeUrlPipe ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  encapsulation:ViewEncapsulation.None
})
export class ModalComponent implements OnInit{

  pmID = '';
  modalBody : any;
  modalTitle : any;
  modalName : any;
  modalValue : any;
  inviteUserForm! : FormGroup;
  isSubmitted : boolean = false;
  isEmailId : any ;
  checkSubDomain : boolean = false;
  decodedDomain : string = '';
  isModalShow : boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { pmID: string , modalBody : string , modalTitle : string , modalName : string , modalValue : string },
    public DataService : DataService,
    private toaster: ToastrService,
    private fb : FormBuilder,
    private commonService : CommonService,
    private eRef: ElementRef
  ) {
    this.pmID = data ? data.pmID : '';
    this.modalBody = data ? data.modalBody : '';
    this.modalTitle = data ? data.modalTitle : '';
    this.modalName = data ? data.modalName : '';
    this.modalValue = data ? data.modalValue : '';
  }

ngOnInit(): void {
  this.isModalShow = true;
  this.inviteUserForm = this.fb.group({
    first_name : ['',[Validators.required]],
    last_name  : ['',[Validators.required]],
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+$')]],
  })
  this.getEmailID()
}

@HostListener('document:click', ['$event'])
clickout(event: any) {  
  const clickedInside = this.eRef.nativeElement.contains(event.target);  
  // If clicked outside the modal and form is dirty, prevent closing
  if (this.isModalShow && !clickedInside 
    && !this.inviteUserForm.get('first_name')?.value 
    && !this.inviteUserForm.get('last_name')?.value 
    && !this.inviteUserForm.get('email')?.value.trim()) {
    this.onCancel()
  }
}

get inviteUserFormControl(){
  return this.inviteUserForm.controls;
}

public getErrorMessage(value: keyof typeof validationMessage.SIGNUP){
  return validationMessage.SIGNUP[value];
}

onInputChange(event: any) {
  const input = event.target;
  input.value = input.value.replace(/\s/g, '');
}

  onCancel(): void {
    this.dialogRef.close(false); 
  }

  onSave(): void {
    this.DataService.setDefaultCard(this.pmID).subscribe((res)=>{
      if(res.status == "success"){
        const result = {
          message: res.data.message,
          success: true
        };
        this.dialogRef.close(result); 
      }else{
        this.dialogRef.close(false); 
      };
    },(err)=>{
      this.toaster.error(err)
    })
  }

  
  deleteCard(): void {
    this.DataService.deleteCard(this.pmID).subscribe((res)=>{
      if(res.status == "success"){
        this.dialogRef.close(res.status); 
      }else{
        this.dialogRef.close(false);  
      }
    },(err)=>{
      this.toaster.error(err)
    })
  }

  onYes(){
    this.dialogRef.close('YES'); 
  }

  onNo(){
    this.dialogRef.close('NO'); 
  }

  onInviteUser(): void {
    this.isSubmitted = true;

    // Get the entered email and the decoded email
    const enteredEmail = this.inviteUserForm.get('email')?.value.toLowerCase();

    if(this.inviteUserForm.invalid){
      return;
    }else{
      this.checkSubDomain = false;
      // Patch the full email in the form before submitting
      this.inviteUserForm.patchValue({
        email: enteredEmail + this.decodedDomain
      });
      this.dialogRef.close(this.inviteUserForm.value)
    }
  }

  public getEmailID():void {
    const token : any = localStorage.getItem('barrier-token')
    if(token && token !== null && token !== undefined){
      const decodedToken = jwt_decode(token);
      this.isEmailId = decodedToken.sub
      this.decodedDomain = '@' + this.isEmailId.split('@')[1];  // Extract domain from decoded email
    }
  }

    onChange(event: any, fieldName: string) {
      const input = event.target;
      const sanitizedValue = this.commonService.sanitizeInput(input.value);
    
      // Update the input value
      input.value = sanitizedValue;
    
      // Set the sanitized value in the form control
      this.inviteUserForm.get(fieldName)?.setValue(sanitizedValue, { emitEvent: false });
    }

}
